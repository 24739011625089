import React, {useState, useEffect} from "react"
// import "../../static/styles/wuds.en.css"
import "@westernunion/css/lang/wuds.en.css"
import "../../static/styles/app-banner.css"
import OutageBanner from "./outage-banner.component"
import AppBanner from "./app-banner.component"
import SignupOverlay from "./signupoverlay.component" 
import {getSwitches , getCurrentCountry} from "../utils/common"
import Language from "./language"



const Layout = (props) => {
  const {appBanner, children, language} = props;
  const [showOverlay, setShowOverlay] = useState(false);
  useEffect(() => {
    let app = sessionStorage.getItem('app') || "{}";
    app = JSON.parse(app);
    const isLoggedIn = app.sender && app.sender.isLoggedIn ? true : false;
    let inIframe = window.location !== window.parent.location;   
    let currentCountry = getCurrentCountry();
    const switches = getSwitches();
    window.addEventListener('load', function () {
      window.loadSignUpOverlay = () => {
        if (!isLoggedIn && !inIframe &&
          currentCountry && switches &&
          switches.signUpOverlayEnabledCountries
            .toLowerCase()
            .indexOf(currentCountry.toLowerCase()) > -1
        ) {
          setShowOverlay(true);
        }
      }
    });
  }, []);

  return (
    <div>
      <OutageBanner/>
      {<AppBanner {...appBanner} />}
      {showOverlay && <SignupOverlay/>}
      {<Language  language={language} />}
      {children}
    </div>
  )
}

export default Layout