import React from "react"
import LanguageEN from "./languageEN"
import LanguageAR from "./languageAR"
import LanguageHE from "./languageHE"
import LanguageRO from "./languageRO"

const Language = ({ 
  language
}) => {
 return <div>
   {(language === "ar" ? <LanguageAR /> :
   (language === "he" ? <LanguageHE />:
   (language === "ro" ? <LanguageRO /> : <LanguageEN />)))}</div>
}

export default Language
