import React from "react";

/**
 * React SignupOverlayIframe Component
 * @component
 * @example
 * return (
 * <SignupOverlayIframe ref={ref} src={props.src}  />
 * )
 */

const SignupOverlayIframe = React.forwardRef((props, ref) => (
  <iframe ref={ref} class="wu-overlay__container bk-white" src={props.src} id='signupOverlayIframe'></iframe>
))

export default SignupOverlayIframe