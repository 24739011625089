import React, { useState, useEffect } from "react"
import { fetchHTML, getEnv, isBrowser } from '../utils/common.js'

const replaceString = (data, baseURL) => {
  return data && typeof data == "string" && data.replace(/\/content\/wucom\//gi, `${baseURL}/content/wucom/`)
}

const loadScripts = (
  url
) => {
  return new Promise((resolve, reject) => {
    var script = document.createElement(`script`)
    script.async = true
    script.src = url
    script.addEventListener(`load`, () => {
      resolve(true)
    })
    script.addEventListener(`error`, () => {
      reject(false)
    })
    document.getElementsByTagName(`body`)[0].appendChild(script)
  })
}

export default function OutageBanner() {
  let [data, setData] = useState([])
  let baseURL = getEnv()
  var otConfigScript = `${baseURL}/content/wucom/outage-banner/outage-configuration.js`
  var otBannerLogicScript = `${baseURL}/content/wucom/outage-banner/outage-banner-logic.js`

  useEffect(() => {
    (() => {
      return new Promise((resolve, reject) => {
        try {
          fetchHTML(`${baseURL}/content/wucom/outage-banner/outage-banner.html`)
            .then(response => {
              setData(response)
              loadScripts(otConfigScript).then((res) => {
                res && loadScripts(otBannerLogicScript)
              })
            })
            .catch(err => {
              console.log(`Error while fetching outage-banner ${err.message}`)
            })
          } catch (err) {
            console.log(`Error while fetching outage-banner ${err.message}`)
          }
        })
    })()
  }, [])

  // Below code will execute only on localhost
  const hostname = isBrowser() && window.location.hostname
  if (hostname && hostname.includes('localhost')) {
    if(data.length > 0){
      data = replaceString(data, baseURL)
    }
  }

  return (
    <div
      dangerouslySetInnerHTML={{ __html: data }}
    />
  )
}
